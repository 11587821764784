import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase/config";
// import products from "../asset/data/products";
import { Box, Button, Typography } from "@mui/material";
import { BsHandThumbsUp } from "react-icons/bs";
import { TiTickOutline } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { cartActions } from "../redux/slice/cartSlice";

export function ProductDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();

  // Find the product based on the provided ID
  // const product = products.find((item) => item.id === id);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const getAccessoryById = async (id) => {
      try {
        const accessoriesRef = collection(db, "products");
        const q = query(accessoriesRef, where("id", "==", id));
        const snapshot = await getDocs(q);

        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            setProduct(doc.data());
            // Do something with the accessoryData
            console.log(product)
          });
        } else {
          // Document does not exist
        }
      } catch (error) {
        console.error("Error fetching accessory:", error);
      }
    };
    getAccessoryById(id);
  }, [product, id]);
  if (!product) {
    return <div>Product not found</div>;
  }
  const addToCart = () => {
    dispatch(cartActions.addItem(product));
  };
  const { title, price, desc, image01 } = product;

  return (
    <>
      <Box
        sx={{
          maxWidth: 1100,
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
          // padding: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              position: "relative",
              flex: { xs: "0 0 100%", md: "0 0 40%" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: { xs: 4, md: 0 },
              // boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)", // Added a subtle box shadow
              // borderRadius: "5px", // Rounded corners
              overflow: "hidden", // Ensures image and price stay within the box boundaries
            }}
          >
            <img
              src={image01}
              alt=""
              style={{ height: "300px", objectFit: "cover" }}
            />
            {/* Added object-fit property to ensure the image fits nicely */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: "80px",
                bgcolor: "#FF1493",
                borderRadius: "5px",
                // border: "2px solid black",
                padding: "2px 8px",
                color: "white",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)", // Added a subtle box shadow
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {price}
              </Typography>
              {/* Increased font weight for emphasis */}
            </Box>
          </Box>
          <Box
            sx={{
              flex: { xs: "0 0 100%", md: "0 0 60%" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: { xs: 0, md: 4 },
            }}
          >
            <Box>
              <Typography variant="h5" align="center">
                {title}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 2,
                }}
              >
                <Box sx={{ textAlign: "center", marginRight: 2 }}>
                  <Typography
                    variant="h5"
                    sx={{ color: "#FC82C4", marginBottom: 1 }}
                  >
                    Reader Votes
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    <BsHandThumbsUp size={30} color="#FC82C4" />
                    <Typography component="span" variant="h6">
                      105
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: 2,
                    height: 60,
                    backgroundColor: "black",
                  }}
                />
                <Box sx={{ textAlign: "center", marginLeft: 2 }}>
                  <Typography
                    variant="h5"
                    sx={{ color: "#FC82C4", marginBottom: 1 }}
                  >
                    Our Verdict
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    <TiTickOutline size={30} color="#FC82C4" />
                    <Typography component="span" variant="h6">
                      Get it
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 2,
                }}
              >
                <Typography variant="h6">
                  Pub Date: January 11, 2022
                  <br />
                  ISBN: 978-0-385-54793-2
                </Typography>
                <Typography variant="h6" sx={{ marginLeft: 4 }}>
                  Page count: 720pp
                  <br />
                  Publisher: Doubleday
                </Typography>
              </Box> */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 4,
                }}
              >
                <Button
                  variant="contained"
                  sx={{ bgcolor: "#FF1493", color: "white", marginRight: 2 }}
                >
                  WishList
                </Button>
                <Button
                  variant="contained"
                  sx={{ bgcolor: "#FF1493", color: "white", marginLeft: 2 }}
                  onClick={addToCart}
                >
                  Add To Cart
                </Button>
              </Box>
            </Box>
          </Box>
          <Box sx={{ marginTop: 4 }}>
            <Typography
              variant="h6"
              align="center"
              sx={{ color: "#FC82C4", marginBottom: 2 }}
            >
              Description
            </Typography>
            <Typography variant="body1" align="center" sx={{ marginBottom: 4 }}>
              {desc}
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{ width: "70%", height: 1, backgroundColor: "#FC82C4" }}
              />
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
}
