import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";
import { getDatabase } from "firebase/database";

export const firebaseConfig = {
  // apiKey: "AIzaSyD1FxytltzqhkRNWjSUk29x6nSuiNRLDuc",
  // authDomain: "ecommerce-7b57f.firebaseapp.com",
  // projectId: "ecommerce-7b57f",
  // storageBucket: "ecommerce-7b57f.appspot.com",
  // messagingSenderId: "744727378268",
  // appId: "1:744727378268:web:19a7b472d6ba3d270fa999"
  apiKey: "AIzaSyA0PNY9lR8mNlUbZ3SxPPndD-xHb56s7TM",
  authDomain: "ecommerce-6b967.firebaseapp.com",
  projectId: "ecommerce-6b967",
  storageBucket: "ecommerce-6b967.appspot.com",
  messagingSenderId: "939420484011",
  appId: "1:939420484011:web:f611a1fb2405b40eef12ee"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const database = getDatabase(app);
export default app;