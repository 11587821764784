import { configureStore, combineReducers } from "@reduxjs/toolkit";
import cartSlice from "./slice/cartSlice";
import authReducer from "./slice/authSlice";

const rootReducer = combineReducers({
  cart: cartSlice,
  auth: authReducer,

});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
