import React from "react";
import { styled } from "@mui/system";
import { Box, Button, Typography } from "@mui/material";
import logo from "../asset/images/logo192.png";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ProductItem } from "../components/CartItem";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Container = styled("div")(({ theme }) => ({
  position: "relative",
  paddingTop: "50px",
  maxWidth: "1100px",
  margin: "0 auto",
  minHeight: "100%",
  display: "grid",
  rowGap: "30px",
  gridTemplateColumns: "1fr", // Set the grid to have 1 column
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr",
    // padding: "20px",
  },
}));

const StyledTable = styled("table")({
  width: "100%",
  borderCollapse: "collapse",
  borderRadius: "5px",
  overflow: "hidden",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
});

const StyledTableHead = styled("thead")({
  backgroundColor: "#FC82C4",
});

const StyledTableHeader = styled("th")({
  padding: "12px",
  color: "white",
  fontWeight: "bold",
  textTransform: "uppercase",
  textAlign: "center",
});

const StyledTableRow = styled("tr")({
  "&:nth-of-type(even)": {
    backgroundColor: "#f2f2f2",
  },
});

export const Cart = () => {
  const cartProducts = useSelector((state) => state.cart.cartItems);
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const navigate = useNavigate();
  const handleCheckOut = () => {
    if (isLoggedIn) {
      navigate("/delivery");
    }else{
      toast.warn("Please Login before checkout")
      navigate("/login");
    }
  };

  function handleClick() {
    window.location.href =
      "https://www.amazon.com/Unfolding-Resilient-Journey-Through-Pandemic/dp/B09BYFWY58/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr=";
  }

  return (
    <div>
      <ToastContainer/>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cart</title>
      </Helmet>
      <Container>
        <div>
          <StyledTable>
            <StyledTableHead>
              <StyledTableRow>
                <StyledTableHeader>PRODUCT</StyledTableHeader>
                <StyledTableHeader>ITEM NAME</StyledTableHeader>
                <StyledTableHeader>QUANTITY</StyledTableHeader>
                <StyledTableHeader>PRICE</StyledTableHeader>
              </StyledTableRow>
            </StyledTableHead>
            <tbody>
              {cartProducts.length > 0 ? (
                cartProducts.map((item) => (
                  <ProductItem key={item.id} item={item} />
                ))
              ) : (
                <tr>
                  <td colSpan={4} style={{ textAlign: "center" }}>
                    No items in the cart.
                  </td>
                </tr>
              )}
            </tbody>
          </StyledTable>
        </div>
      </Container>

      <div
        style={{
          // marginTop: "80px",
          // marginBottom: "20px",
          paddingTop: "80px",
          paddingBottom: "20px",
          width: "100%",
        
          // height: "200px",
          maxWidth: "1100px",
          margin: "0 auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            backgroundColor: "#f8f8f8", // Update background color
            padding: "20px",
            borderRadius: "5px",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            flexDirection: "row", // Set initial flex direction to row
            "@media (max-width: 600px)": {
              flexDirection: "column", // Change flex direction to column on small screens
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={logo} alt="logo" width={"300px"}/>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="h4" style={{ color: "black" }}>
                TOTAL PRICE:
              </Typography>
              <Typography variant="h4" style={{ color: "#FF1493" }}>
                Rs.{totalAmount}
              </Typography>
            </Box>
            <Box sx={{ marginTop: "20px" }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#FF1493", // Update button color
                  color: "white",
                  padding: "6px 20px",
                  fontSize: "16px",
                  border: "none",
                  borderRadius: "20px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  transition: "background-color 0.3s ease",
                  marginRight: "20px",
                }}
                disabled={cartProducts.length === 0}
                onClick={handleCheckOut}
              >
                CHECK OUT
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#FF1493", // Update button color
                  color: "white",
                  padding: "6px 20px",
                  fontSize: "16px",
                  border: "none",
                  borderRadius: "20px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  transition: "background-color 0.3s ease",
                }}
                onClick={handleClick}
              >
                AMAZON
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};
