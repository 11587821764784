import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
  totalQuantity: 0,
  totalAmount: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (state, action) => {
      const newItem = action.payload;
      console.log(newItem, "data1");

      const existingItemIndex = state.cartItems.findIndex(
        (item) => Number(item.id) === Number(newItem.id)
      );

      if (existingItemIndex === -1) {
        state.cartItems = [
          ...state.cartItems,
          {
            id: newItem.id,
            title: newItem.title,
            image01: newItem.image01,
            price: newItem.price,
            quantity: 1,
            totalPrice: newItem.price,
            desc: newItem.desc,
          },
        ];
      } else {
        state.cartItems = state.cartItems.map((item, index) => {
          if (index === existingItemIndex) {
            return {
              ...item,
              quantity: item.quantity + 1,
              totalPrice: Number(item.totalPrice) + Number(newItem.price),
            };
          }
          return item;
        });
      }

      state.totalQuantity = state.cartItems.reduce(
        (total, item) => total + item.quantity,
        0
      );

      state.totalAmount = state.cartItems.reduce(
        (total, item) => total + Number(item.price) * item.quantity,
        0
      );
    },

    removeItem: (state, action) => {
      const id = action.payload;
      const existingItem = state.cartItems.find((item) => item.id === id);
      state.totalQuantity--;
      if (existingItem.quantity === 1) {
        state.cartItems = state.cartItems.filter((item) => item.id !== id);
      } else {
        existingItem.quantity--;
        existingItem.totalPrice =
          Number(existingItem.totalPrice) - Number(existingItem.price);
      }

      state.totalAmount = state.cartItems.reduce(
        (total, item) => total + Number(item.price) * Number(item.quantity),
        0
      );
    },

    deleteItem(state, action) {
      const id = action.payload;
      const existingItem = state.cartItems.find((item) => item.id === id);
      if (existingItem) {
        state.cartItems = state.cartItems.filter((item) => item.id !== id);
        state.totalQuantity = state.totalQuantity - existingItem.quantity;
      }
      state.totalAmount = state.cartItems.reduce(
        (total, item) => total + Number(item.price) * Number(item.quantity),
        0
      );
    },
  },
});

export const cartActions = cartSlice.actions;
export const { addItem, removeItem, deleteItem } = cartSlice.actions;
export default cartSlice.reducer;