import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addItem } from "../redux/slice/cartSlice";
import { useSelector } from "react-redux";
import { AiOutlineAmazon } from "react-icons/ai";
import {
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";

function ProductCart({ item }) {
  // const { id, title, image01, price } = item;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.cart.cartItems);

  const addToCart = () => {
    dispatch(addItem(item));
    console.log(item, "data");
    // console.log(item)
    console.log(data);
  };
  useEffect(() => {
    console.log(data);
  }, [data]);

  const showDetails = () => {
    navigate(`/product/${item.id}`);
  };

  const imageStyles = {
    width: "100%",
    height: "200px",
    // objectFit: "cover",
    borderRadius: "8px 8px 0 0",
    // display: "block",

    // margin: "0 auto",
  };

  const titleStyles = {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "5px",
    textAlign: "center",
    color: "#333",
    textTransform: "capitalize",
    lineHeight: "1.2",
  };

  const priceContainerStyles = {
    display: "flex",
    alignItems: "center",
    // justifyContent: "flex-start",
    justifyContent: "space-between",
    marginBottom: "5px",
  };

  const priceLabelStyles = {
    fontSize: "14px",
    color: "#888",
    marginRight: "5px",
  };

  const priceValueStyles = {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#FF4081",
    marginRight: "10px",
  };

  const containerStyles = {
    display: "flex",
    justifyContent: "center",
  };

  const cardStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    borderRadius: "8px",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    maxWidth: "300px",
    "&:hover": {
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    },
  };

  const buttonStyles = {
    backgroundColor: "#FF4081",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#E01A62",
    },
  };

  const iconStyles = {
    backgroundColor: "#FF4081",
    fontSize: "24px",

    // padding: "5px 5px",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#E01A62",
    },
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={4} key={item.id}>
        <div style={containerStyles}>
          <Card style={cardStyles}>
            <img src={item.image01} alt={item.title} style={imageStyles} />
            <CardContent>
              <Typography variant="h6" style={titleStyles}>
                {item.title}
              </Typography>
              <div style={priceContainerStyles}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" style={priceLabelStyles}>
                    Price:
                  </Typography>
                  <Typography variant="h6" style={priceValueStyles}>
                    Rs {item.price.toFixed(2)}
                  </Typography>
                </div>
                <div>
                  {item.Amazon !== "" && (
                    <a href={item.Amazon}>
                      <AiOutlineAmazon style={iconStyles} />
                    </a>
                  )}
                </div>
              </div>
            </CardContent>

            <CardActions style={{ marginTop: "-15px" }}>
              <Button
                variant="contained"
                style={buttonStyles}
                onClick={addToCart}
              >
                Add to Cart
              </Button>
              <Button variant="outlined" onClick={showDetails}>
                Details
              </Button>
            </CardActions>
          </Card>
        </div>
      </Grid>
    </>
  );
}

export default ProductCart;
