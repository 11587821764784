import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import profileImage from "../asset/images/Naila.jpg";
import { Helmet } from "react-helmet";
import book from "../asset/images/book_poster.png";

const StyledProfileImage = styled("img")`
  width: 100%;
  height: auto;
  height: 30%;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;


export function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home</title>
      </Helmet>

      <Container maxWidth="md">
        <Box
          bgcolor="#FF1493"
          p={2}
          borderRadius={4}
          textAlign="center"
          marginBottom={3}
          marginTop={3}
          maxWidth={1100}
          width="100%"
        >
        <Typography
          variant="h4"
          component="h1"
          sx={{
            color: 'white',
            fontWeight: 'bold',
            letterSpacing: '1.5px',
            fontSize: {
              xs: '1.5rem', // Font size for mobile screens
              sm: '2rem', // Font size for desktop screens
            },
          }}
        >
            UNFOLDING INC
          </Typography>
        </Box>
        <Typography variant="body1" gutterBottom>
          UNFOLDING INC is an author platform to promote books and other
          publications by internationally acclaimed writer Naila Tasneem. We
          also provide consulting and capacity building services for women and
          young professionals to achieve phenomenal professional growth and
          provide help to seize local and international opportunities. Our
          platform is also recognized by renowned international organizations
          such as FIGHR (Mission) USA, US Mission Pakistan, LUMS and other
          empowerment groups in Africa, UK and USA. We are also involved in
          global advocacy on human and gender rights, peer mental health, SDGs
          and women empowerment.{" "}
        </Typography>
      </Container>

      <Container maxWidth="md">
        <Box
          bgcolor="#FF1493"
          p={2}
          borderRadius={4}
          textAlign="center"
          marginBottom={3}
          marginTop={3}
          maxWidth={1100}
          width="100%"
        >
          <Typography
            variant="h4"
            component="h1"
            sx={{
              color: 'white',
              fontWeight: 'bold',
              letterSpacing: '1.5px',
              fontSize: {
                xs: '1.5rem', // Font size for mobile screens
                sm: '2rem', // Font size for desktop screens
              },
            }}
          >
            ABOUT THE AUTHOR
          </Typography>
        </Box>
        <StyledProfileImage src={profileImage} alt="Naila Tasneem" />

        <Typography variant="body1" gutterBottom >
          Naila Tasneem is an economic analyst, author and a global peer mental
          health advocate. She was born on 8th August 1986 in Lahore, Pakistan.
          She has emerged as a global writer with contributions in local,
          national and international publications. In the past few years, she
          has won several awards and honors globally for Pakistan. Her
          contributions range from SDGs to women empowerment advocacy. She is
          also an international speaker and has advocated for gender and human
          rights across various international platforms in Africa, US, UK and
          Pakistan. She is a member of a variety of professional associations
          and women empowerment groups such as I’m HERRS Girl group, out of the
          Ashes women empowerment group in California and Federation of
          International Gender and Human Rights in New York, USA. In 2021. She
          has featured in the Global Agenda 2021-2023 publication in London by
          the Global Diplomatic Forum among leading intellectuals from around
          the world. Her quest for promoting women empowerment internationally
          led her to become part of “Let’s Write International Women Writers
          Group” conducted in California, USA in May 2021 by the UN. She has
          published her first book “Unfolding: A Resilient Journey through the
          Pandemic” by Kairos editing and publishing in California, USA on
          August 4, 2021. Her book is available worldwide on Amazon. The book is
          a memoir and a self-help guide and discusses the writers’ personal and
          professional journey through the pandemic. She is also a Diplomatic
          Ambassador for Federation of International Gender and Human Rights,
          New York in Pakistan.
        </Typography>
        <StyledProfileImage src={book} alt="Naila Tasneem" />
      </Container>
    </>
  );
}
