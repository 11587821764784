import React from "react";
import { TextField, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../style/SignUp.css";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase/config";
import { collection, doc, setDoc } from "firebase/firestore";

export const SignUp = () => {
  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Reset error messages

    // Get form values
    const email = e.target.email.value;
    const username = e.target.username.value;
    const password = e.target.password.value;
    const confirmPassword = e.target.confirmPassword.value;

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate form
    if (!email) {
      toast.error("Please enter your email");
      return;
    }

    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email");
      return;
    }

    if (!username) {
      toast.error("Please enter your username");
      return;
    }

    if (!password) {
      toast.error("Please enter your password");
      return;
    }

    if (password.length < 6) {
      toast.error("Password must be at least 6 characters long");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      // Start loader
      // setIsLoading(true);

      // Create user with email and password
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredentials) => {
          // Stop loader
          // setIsLoading(false);

          // Successful sign up
          toast.success("Sign up successful");

          // Save additional user data to Firestore
          const user = userCredentials.user;
          const userData = {
            email: user.email,
            username: username,
            isAdmin: false, // Modify this field as needed
          };

          // Add the user data to Firestore
          setDoc(doc(collection(db, "users"), user.uid), userData);

          navigate("/login");
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } catch (error) {
      // Handle error
      toast.error("An error occurred");
      // setIsLoading(false);
    }
  };

  const handleLogin = () => {
    navigate("/login");
    // Perform the necessary action to navigate to the login page
  };

  return (
    <div className="container_signup">
      <ToastContainer />
      <img src="logo.png" alt="" srcSet="" />
      <form id="signupForm" onSubmit={handleSubmit}>
        <div className="form-group">
          <TextField
            type="text"
            id="username"
            name="username"
            label="Username"
            fullWidth
            required
            InputLabelProps={{
              style: { color: "white" },
            }}
            InputProps={{
              disableUnderline: true,
              style: { borderBottom: "none" },
            }}
          />
          <div id="usernameError" className="error-message"></div>
        </div>
        <div className="form-group">
          <TextField
            type="email"
            id="email"
            name="email"
            label="Email"
            fullWidth
            required
            InputLabelProps={{
              style: { color: "white" },
            }}
            InputProps={{
              disableUnderline: true,
              style: { borderBottom: "none" },
            }}
          />
          <div id="emailError" className="error-message"></div>
        </div>
        <div className="form-group">
          <TextField
            type="password"
            id="password"
            name="password"
            label="Password"
            fullWidth
            required
            InputLabelProps={{
              style: { color: "white" },
            }}
            InputProps={{
              disableUnderline: true,
              style: { borderBottom: "none" },
            }}
          />
          <div id="passwordError" className="error-message"></div>
        </div>
        <div className="form-group">
          <TextField
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            fullWidth
            required
            InputLabelProps={{
              style: { color: "white" },
            }}
            InputProps={{
              disableUnderline: true,
              style: { borderBottom: "none" },
            }}
          />
          <div id="confirmPasswordError" className="error-message"></div>
        </div>
        <div className="form-group">
          <Button type="submit" variant="contained" fullWidth>
            Sign Up
          </Button>
        </div>
      </form>
      <div className="already-account">
        <Typography>
          Already have an account?{" "}
          <span
            style={{
              color: "white",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={handleLogin}
          >
            Login here
          </span>
        </Typography>
      </div>
    </div>
  );
};
