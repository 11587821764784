import React from "react";
import { Container, Grid, Typography, Button } from "@mui/material";
import { FaPhone, FaTwitter, FaInstagram, FaFacebook } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import logo from "../asset/images/logo192.png";

const maindivStyle = {
  maxWidth: 1100,
  margin: "0 auto",
  padding: 60,
};

const containerStyle = {
  display: "flex",
  flexWrap: "wrap",
  padding: "90px 0",
  justifyContent: "center",
};

const threeStyle = {
  flex: 2,
};

const fourStyle = {
  flex: 2,
};

const customerStyle = {
  display: "flex",
  justifyContent: "center",
  paddingLeft: 30,
  marginTop: 20,
};

const contactStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: 10,
};

const iconStyle = {
  marginRight: 10,
  color: "#FF1493",
  fontSize: 24,
};

const contactTextStyle = {
  fontFamily: "Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif",
  fontSize: 16,
  lineHeight: 1.5,
  color: "#333",
};

const headingStyle = {
  color: "#FF1493",
  fontSize: 32,
  fontWeight: "bold",
  marginBottom: 20,
  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
};

const menuItemStyle = {
  fontSize: 18,
  fontWeight: "bold",
  marginBottom: "10px",
  whiteSpace: "nowrap",
  background: "#FF1493",
};

const logoStyle = {
  maxWidth: "80%",
  // height: "auto",
  // display: "block",
  // marginLeft: "auto",
  // marginRight: "auto",
  paddingLeft: "10%",
  // marginBottom: 30,
  // border: "2px solid red",
};

export const Contact = () => {
  return (
    <div style={maindivStyle}>
      <img src={logo} alt="logo" style={logoStyle} />
      <Container style={containerStyle}>
        <Grid item xs={12} sm={6} style={threeStyle}>
          <Typography variant="body1">
            🌟 Unlock a World of Literary Treasures! 📚 Embark on a Reading
            Journey Like No Other! 🚀{" "}
          </Typography>
          <div style={contactStyle}>
            <FaPhone style={iconStyle} />
            <Typography style={contactTextStyle}>0326 4053408</Typography>
          </div>
          <div style={contactStyle}>
            <GrMail style={iconStyle} />
            <Typography style={contactTextStyle}>
              unfolding.inc1@gmail.com
            </Typography>
          </div>
          <div style={contactStyle}>
            <a
              href="https://twitter.com/npanthers/status/1424328701911650307"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "flex" }}
            >
              <FaTwitter style={iconStyle} />
              <Typography style={contactTextStyle}>
                Follow us on Twitter
              </Typography>
            </a>
          </div>
          <div style={contactStyle}>
            <a
              href="https://www.instagram.com/naynasupercool/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "flex" }}
            >
              <FaInstagram style={iconStyle} />
              <Typography style={contactTextStyle}>
                Follow us on Instagram
              </Typography>
            </a>
          </div>
          <div style={contactStyle}>
            <a
              href="https://m.facebook.com/p/Naila-Tasneem-Author-100060830047877/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "flex" }}
            >
              <FaFacebook style={iconStyle} />
              <Typography style={contactTextStyle}>
                Like us on Facebook
              </Typography>
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} style={fourStyle}>
          <Typography variant="h2" style={headingStyle}>
            CUSTOMER SERVICE
          </Typography>
          <div style={customerStyle}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "10px",
              }}
            >
              <Button variant="contained" style={menuItemStyle}>
                About Us
              </Button>
              <Button variant="contained" style={menuItemStyle}>
                Books
              </Button>
              <Button variant="contained" style={menuItemStyle}>
                Articles
              </Button>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Button variant="contained" style={menuItemStyle}>
                Magazine
              </Button>
              <Button variant="contained" style={menuItemStyle}>
                Events
              </Button>
              <Button variant="contained" style={menuItemStyle}>
                Contact
              </Button>
            </div>
          </div>
        </Grid>
      </Container>
    </div>
  );
};
