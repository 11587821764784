import React, { useEffect, useState } from "react";
import "../style/Header.css";
import { FaBars } from "react-icons/fa";
import { BsCartDash } from "react-icons/bs";
import { HiOutlineUser, HiOutlineLogout } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from "../firebase/config";
import logo from "../asset/images/logo192.png";
import {
  SET_ACTIVE_USER,
  REMOVE_ACTIVE_USER,
  selectIsLoggedIn,
  SET_ADMIN_USER,
} from "../redux/slice/authSlice";
import { toast, ToastContainer } from "react-toastify";
import { collection, doc, getDoc } from "firebase/firestore";

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleLogoutClick = () => {
    signOut(auth)
      .then(() => {
        toast.success("Logout Successful");
      })
      .catch((error) => {
        toast.error(error.message);
      });
    dispatch(REMOVE_ACTIVE_USER());
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(
          SET_ACTIVE_USER({
            email: user.email,
            userID: user.uid,
          })
        );

        const fetchUser = async () => {
          try {
            const userRef = doc(collection(db, "users"), user.uid);
            const userSnapshot = await getDoc(userRef);
            if (userSnapshot.exists()) {
              const userData = userSnapshot.data();
              if (userData.isAdmin) {
                dispatch(SET_ADMIN_USER());
              }
            } else {
              console.log("User does not exist");
            }
          } catch (error) {
            console.error("Error fetching user: ", error);
          }
        };
        fetchUser();
      }
    });
  }, [dispatch]);

  const handleClick = () => {
    navigate("/cart");
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = () => {
    setIsMenuOpen(false);
  };

  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <>
      <ToastContainer />

      <nav className={isMenuOpen ? "fixed" : ""}>
        <input
          type="checkbox"
          id="check"
          checked={isMenuOpen}
          onChange={handleMenuToggle}
        />
        <label htmlFor="check" className="checkbtn">
          <i>
            <FaBars color="#FF1493" />
          </i>
        </label>
        <label
          htmlFor=""
          style={{ position: "relative" }}
          className="checkbtntt"
          onClick={handleClick}
        >
          <i>
            <BsCartDash color="#FF1493" />
          </i>
          <span
            style={{
              position: "absolute",
              fontSize: "0.8rem",
              background: "#df2020",
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              fontWeight: "500",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              right: "-10px",
              top: "10px",
            }}
          >
            {totalQuantity}
          </span>
        </label>
        {isLoggedIn ? (
          // Logout button
          <label htmlFor="" className="checkbtntt" onClick={handleLogoutClick}>
            <i>
              <HiOutlineLogout color="#FF1493" />
            </i>
          </label>
        ) : (
          // Login button
          <label htmlFor="" className="checkbtntt" onClick={handleLoginClick}>
            <i>
              <HiOutlineUser color="#FF1493" />
            </i>
          </label>
        )}

        <label className="logo"><img src={logo} alt="logo" width={"100px"} /></label>
        

        <ul>
          <li>
            <Link
              to="/"
              className={`nav-link ${isActive("/")}`}
              onClick={handleMenuItemClick}
            >
              HOME
            </Link>
          </li>
          <li>
            <Link
              to="/booklist"
              className={`nav-link ${isActive("/booklist")}`}
              onClick={handleMenuItemClick}
            >
              BOOKS
            </Link>
          </li>
          <li>
            <Link
              to="/accessories"
              className={`nav-link ${isActive("/accessories")}`}
              onClick={handleMenuItemClick}
            >
              ACCESSORIES
            </Link>
          </li>
          <li>
            <Link
              to="/article"
              className={`nav-link ${isActive("/article")}`}
              onClick={handleMenuItemClick}
            >
              ARTICLES
            </Link>
          </li>
          
          <li>
            <Link
              to="/event"
              className={`nav-link ${isActive("/event")}`}
              onClick={handleMenuItemClick}
            >
              EVENTS
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className={`nav-link ${isActive("/contact")}`}
              onClick={handleMenuItemClick}
            >
              CONTACT
            </Link>
          </li>

          {isAdmin && (
            <li>
              <Link
                to="/admin"
                className={`nav-link ${isActive("/admin")}`}
                onClick={handleMenuItemClick}
              >
                ADMIN
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </>
  );
}
