import React from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Typography } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { auth } from "../firebase/config";
import "../style/login.css";

export const Login = () => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Reset error messages

    // Get form values
    const email = e.target.email.value;
    const password = e.target.password.value;

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate form
    if (!email) {
      toast.error("Please enter your email"); // Show error toast
      return;
    }

    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email"); // Show error toast
      return;
    }

    if (!password) {
      toast.error("Please enter your password"); // Show error toast
      return;
    }

    // Successful login
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredentials) => {
        // const user = userCredentials.user;
        toast.success("Login Successful");
        navigate("/cart")
      })
      .catch((error) => {
        toast.error(error.message);
        return;
      });
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    // Perform the necessary action for password recovery (e.g., show a modal, redirect to password recovery page)
  };

  const handleSignUp = () => {
    navigate("/signUp");
  };

  return (
    <div className="container_login">
      <ToastContainer />
      <img src="logo.png" alt="" srcSet="" />
      <form id="loginForm" onSubmit={handleSubmit}>
        <div className="form-group">
          <TextField
            type="email"
            id="email"
            name="email"
            label="Email"
            variant="outlined"
            fullWidth
            required
            InputLabelProps={{
              style: { color: "white" },
            }}
            InputProps={{
              classes: {
                root: "input-root",
                input: "input-field",
              },
            }}
          />
          <div id="emailError" className="error-message"></div>
        </div>
        <div className="form-group">
          <TextField
            type="password"
            id="password"
            name="password"
            label="Password"
            variant="outlined"
            fullWidth
            required
            InputLabelProps={{
              style: { color: "white" },
            }}
          />
          <div id="passwordError" className="error-message"></div>
        </div>
        <div className="form-group">
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
        </div>
      </form>
      <div className="forgot-password">
        <Typography variant="body1">
          <span
            style={{
              color: "blue",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={handleForgotPassword}
          >
            Forgot Password?
          </span>
        </Typography>
      </div>
      <div className="sign-up">
        <Typography variant="body1">
          Don't have an account?{" "}
          <button
            style={{
              color: "white",
              background: "none",
              border: "none",
              padding: "0",
              font: "inherit",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={handleSignUp}
          >
            Sign Up
          </button>
        </Typography>
      </div>
    </div>
  );
};
