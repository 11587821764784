import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Grid, Paper, Typography } from "@mui/material";

export const Article = () => {
  const articles = [
    {
      article:
        "Explore Naila Tasneem's profound perspectives and engaging writing style as she delves into pressing issues and offers unique insights on the pages of Daily Times.",
      link: "https://dailytimes.com.pk/writer/naila-tasneem/",
    },
    {
      article:
        "Discover Naila Tasneem's compelling articles on Pakistan Today, where she skillfully delves into current affairs and offers valuable perspectives on a diverse range of topics.",
      link: "https://archive.pakistantoday.com.pk/author/nailatasneem/",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleCardClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Articles</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <Typography variant="h2" align="center" color="#FC82C4" gutterBottom>
        ARTICLES
      </Typography>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {articles.map((article, index) => (
            <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
              <a
                href={article.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handleCardClick(index)}
              >
                <Paper
                  elevation={activeIndex === index ? 6 : 3}
                  sx={{
                    p: 2,
                    transform: activeIndex === index ? "translateY(-10px)" : "none",
                    transition: "transform 0.3s ease",
                  }}
                >
                  <Typography variant="h5" sx={{ fontSize: "1rem" }}>
                    {article.article}
                  </Typography>
                </Paper>
              </a>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};
