import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import "firebase/firestore";
import ProductCart from "../components/ProductCart";
import { db } from "../firebase/config";
import { collection, getDocs } from "firebase/firestore";
import "../style/productCart.css";

export function BookList() {
  const [products, setProducts] = useState([]);
  const containerStyles = {
    maxWidth: "1100px",
    margin: "0 auto",
  };

  useEffect(() => {
    // Read products collection
    const getProducts = async () => {
      try {
        const productsRef = collection(db, "products");
        const snapshot = await getDocs(productsRef);

        const productsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setProducts(productsData);
        // console.log(productsData)
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    getProducts();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Books</title>
      </Helmet>
      <div style={containerStyles}>
        <Grid container spacing={3}>
          {products.map((item) => (
            <ProductCart key={item.id} item={item} />
          ))}
        </Grid>
      </div>
    </>
  );
}
