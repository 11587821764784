import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import bank from "../asset/images/bank.jpg";
import jazzCash from "../asset/images/jazzCash.jpg";
import easyPaisa from "../asset/images/easyPaisa.jpg";

import { useSelector } from "react-redux";
import { collection } from "firebase/firestore";
import { db } from "../firebase/config";
import { doc, setDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const CashOnDeliveryForm = () => {
  const userId = useSelector((state) => state.auth.userID);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [fullNumber, setFullNumber] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("cash");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await setDoc(doc(collection(db, "checkout"), userId), {
        fullName,
        email,
        fullAddress,
        fullNumber,
      });

      const cartRef = doc(db, "cart", userId);
      await setDoc(cartRef, { items: cartItems });

      setFullName("");
      setEmail("");
      setFullAddress("");
      setFullNumber("");
      toast.success("Order has been placed successfully");
      navigate("/booklist");
      console.log("Data saved successfully");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <ToastContainer />

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mb={4}
          mt={4}
        >
          <Button
            variant={paymentMethod === "cash" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setPaymentMethod("cash")}
            style={{ marginRight: "10px" }}
          >
            Cash on Delivery
          </Button>

          <Button
            variant={paymentMethod === "online" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setPaymentMethod("online")}
          >
            Online Payment
          </Button>
        </Box>

        {paymentMethod === "cash" ? (
          <form
            style={{ maxWidth: "400px", margin: "0 auto" }}
            onSubmit={handleSubmit}
          >
            <TextField
              type="text"
              value={fullName}
              onChange={(event) => setFullName(event.target.value)}
              placeholder="Full Name"
              style={{ width: "100%", marginTop: "5px" }}
              required
            />
            <TextField
              id="address"
              name="address"
              value={fullAddress}
              onChange={(event) => setFullAddress(event.target.value)}
              placeholder="Full Address"
              style={{ width: "100%", marginTop: "5px" }}
              required
            />
            <TextField
              type="tel"
              id="phone"
              name="phone"
              value={fullNumber}
              onChange={(event) => setFullNumber(event.target.value)}
              placeholder="Phone Number"
              style={{ width: "100%", marginTop: "5px" }}
              required
            />
            <TextField
              type="email"
              name="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Email"
              style={{ width: "100%", marginTop: "5px" }}
              required
            />

            <Button
              type="submit"
              style={{
                width: "100%",
                padding: "10px",
                marginTop: "10px",
                backgroundColor: "#FF1493",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              variant="contained"
              color="primary"
            >
              Place Order
            </Button>
          </form>
        ) : (
          <Container maxWidth="md">
            <Box
              mt={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Card>
                    <CardMedia
                      component="img"
                      height={"200px"}
                      src={jazzCash}
                      alt="JazzCash"
                    />
                    <CardContent>
                      <Typography variant="h5" component="h2">
                        Jazz Cash
                        <br />
                        0326 4053408
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card>
                    <CardMedia
                      height={"200px"}
                      component="img"
                      src={easyPaisa}
                      alt="EasyPaisa"
                    />
                    <CardContent>
                      <Typography variant="h5" component="h2">
                        Easy Paisa
                        <br />
                        03434716623
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card>
                    <CardMedia
                      component="img"
                      height={"200px"}
                      src={bank}
                      alt="Bank"
                    />
                    <CardContent>
                      <Typography variant="h5" component="h2">
                        Bank of Punjab
                        <br />
                        Account no: 6010003839900019
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>

            <Box mt={4} textAlign="center">
              <Typography variant="body1">
                Please send a screenshot of the payment receipt to the JazzCash
                number (0326 4053408).
              </Typography>
            </Box>

            <form
              style={{ maxWidth: "400px", margin: "0 auto" }}
              onSubmit={handleSubmit}
            >
              <TextField
                type="text"
                value={fullName}
                onChange={(event) => setFullName(event.target.value)}
                placeholder="Full Name"
                style={{ width: "100%", marginTop: "5px" }}
                required
              />
              <TextField
                id="address"
                name="address"
                value={fullAddress}
                onChange={(event) => setFullAddress(event.target.value)}
                placeholder="Full Address"
                style={{ width: "100%", marginTop: "5px" }}
                required
              />
              <TextField
                type="tel"
                id="phone"
                name="phone"
                value={fullNumber}
                onChange={(event) => setFullNumber(event.target.value)}
                placeholder="Phone Number"
                style={{ width: "100%", marginTop: "5px" }}
                required
              />
              <TextField
                type="email"
                name="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Email"
                style={{ width: "100%", marginTop: "5px" }}
                required
              />

              <Button
                type="submit"
                style={{
                  width: "100%",
                  padding: "10px",
                  marginTop: "10px",
                  backgroundColor: "#FF1493",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                variant="contained"
                color="primary"
              >
                Place Order
              </Button>
            </form>
          </Container>
        )}
      </div>
    </>
  );
};
