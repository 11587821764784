import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import "firebase/firestore";
// import ProductCart from "../components/ProductCart";
import { AccessoriesCart } from "../components/AccessoriesCart";
import { db } from "../firebase/config";
import { collection, getDocs } from "firebase/firestore";
import "../style/productCart.css";

export function Accessories() {
  const [accessories, setAccessories] = useState([]);
  const containerStyles = {
    maxWidth: "1100px",
    margin: "0 auto",
  };

  useEffect(() => {
    // Read accessories collection
    const getAccessories = async () => {
      try {
        const accessoriesRef = collection(db, "accessories");
        const snapshot = await getDocs(accessoriesRef);

        const accessoriesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setAccessories(accessoriesData);
        console.log(accessoriesData);
      } catch (error) {
        console.error("Error fetching accessories:", error);
      }
    };

    getAccessories();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Accessories</title>
      </Helmet>
      <div style={containerStyles}>
        <Grid container spacing={3}>
          {accessories.map((item) => (
            <AccessoriesCart key={item.id} item={item} />
          ))}
        </Grid>
      </div>
    </>
  );
}