import React, { useEffect, useState } from "react";
import { Typography, Card, CardContent, Box } from "@mui/material";
import { Helmet } from "react-helmet";
import { db } from "../firebase/config";
import { collection, getDocs } from "firebase/firestore";
import ReactPlayer from "react-player";
import { collectionGroup } from "firebase/firestore";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const Event = () => {
  const [products, setProducts] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productsRef = collection(db, "events");
        const productsSnapshot = await getDocs(productsRef);
        const productsData = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        
        const imagesRef = collectionGroup(db, "images");
        const imagesSnapshot = await getDocs(imagesRef);
        const imagesData = imagesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        
        setProducts(productsData);
        setImages(imagesData[0].images);
        // console.log();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{products.length > 0 ? products[0].title : ""}</title>
      </Helmet>
      {products.length > 0 && (
        <Box
          display="flex"
          justifyContent="center"
          marginTop="20px"
          marginBottom="20px"
        >
          <Card sx={{ maxWidth: "md" }}>
            <CardContent>
              <Box
                bgcolor="#FF1493"
                p={2}
                borderRadius={4}
                textAlign="center"
                marginBottom={3}
                marginTop={3}
                maxWidth={1100}
                width="100%"
              >
                <Typography
                  variant="h4"
                  component="h1"
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    letterSpacing: "1.5px",
                    fontSize: {
                      xs: "1.5rem", // Font size for mobile screens
                      sm: "2rem", // Font size for desktop screens
                    },
                  }}
                >
                  {products[0].title}
                </Typography>
              </Box>
              <Typography variant="subtitle1" color="textSecondary">
                Date: 10th March 2023
              </Typography>
            </CardContent>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Carousel
                showThumbs={false}
                showStatus={false}
                autoPlay
                infiniteLoop
                interval={2000}
                transitionTime={500}
              >
                {images.map((image, index) => (
                  <div key={index}>
                    <img src={image} alt={`Slide ${index}`} />
                  </div>
                ))}
              </Carousel>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              {products[0].video && (
                <ReactPlayer
                  url={products[0].video}
                  controls
                  width="800px"
                  height="450px"
                />
              )}
            </div>
          </Card>
        </Box>
      )}
    </>
  );
};
