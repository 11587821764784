import React from "react";
import { styled } from "@mui/system";
import { Box, Button, Typography } from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { cartActions } from "../redux/slice/cartSlice";

const Image = styled("img")({
  width: "100%",
  height: "100px",
  objectFit: "cover",
  borderRadius: "5px",
});

const DetailProduct = styled("div")({
  padding: "20px",
});

const StyledTableRow = styled("tr")({
  "&:nth-of-type(even)": {
    backgroundColor: "#f2f2f2",
  },
});

const StyledTableCell = styled("td")({
  padding: "12px",
  textAlign: "center",
});

const Cross = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 600px)": {
    flexDirection: "column", // Change flex direction to column on small screens
  },
});

const CounterBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  background: "pink",
  border: "1px solid pink",
  width: "140px",
});
export const ProductItem = ({ item }) => {
  const { id, title, image01, price, quantity, totalPrice, desc } = item;
  const dispatch = useDispatch();
  const incrementItem = () => {
    dispatch(
      cartActions.addItem({
        id,
        title,
        price,
        image01,
        desc,
      })
    );
    // console.log(item)
  };
  const decreaseItem = () => {
    dispatch(cartActions.removeItem(id));
  };
  const deleteItem = () => {
    dispatch(cartActions.deleteItem(id));
  };
  return (
    <StyledTableRow>
      <StyledTableCell>
        <Image src={image01} alt="" />
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body2">{title}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CounterBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row", // Set initial flex direction to row
              "@media (max-width: 600px)": {
                flexDirection: "column", // Change flex direction to column on small screens
                width: "66px"
              },
            }}
          >
            <Button
              variant="outlined"
              sx={{
                border: "none",
                background: "pink",
                borderRadius: "4px",
                padding: "2px",
                fontSize: "20px",
                "@media (max-width: 600px)": {
                  padding:"0px" // Change flex direction to column on small screens
                  
                },
              }}
              onClick={decreaseItem}
            >
              -
            </Button>
            <Typography id="counter" variant="body1">
              {quantity}
            </Typography>
            <Button
              variant="outlined"
              sx={{
                border: "none",
                background: "pink",
                borderRadius: "4px",
                padding: "2px",
              }}
              onClick={incrementItem}
            >
              +
            </Button>
          </CounterBox>
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <Cross>
          <DetailProduct>Rs.{totalPrice}</DetailProduct>
          <RxCross2 onClick={deleteItem} />
        </Cross>
      </StyledTableCell>
    </StyledTableRow>
  );
};
