import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase/config";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Select,
  MenuItem,
  FormControl,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { MdExpandMore, MdDelete, MdInfo } from "react-icons/md";

export const AdminPanel = () => {
  const [checkouts, setCheckouts] = useState([]);
  const [cartData, setCartData] = useState({});
  const [expandedCheckoutId, setExpandedCheckoutId] = useState(null);

  const StyledListItem = styled("li")({
    display: "flex",
    alignItems: "center",
    padding: "8px",
    backgroundColor: "#f5f5f5",
    borderRadius: "4px",
  });

  const CenteredBox = styled(Box)({
    display: "flex",
    alignItems: "center",
  });

  useEffect(() => {
    // Fetch checkouts data
    const fetchCheckouts = async () => {
      try {
        const checkoutsSnapshot = await getDocs(collection(db, "checkout"));
        const fetchedCheckouts = checkoutsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCheckouts(fetchedCheckouts);
        console.log(fetchedCheckouts);

        // Fetch cart data for all checkouts concurrently
        const fetchCartDataPromises = fetchedCheckouts.map((checkout) =>
          fetchCartData(checkout.id)
        );
        await Promise.all(fetchCartDataPromises);
      } catch (error) {
        console.error("Error fetching checkouts: ", error);
      }
    };

    fetchCheckouts();
  }, []);

  const fetchCartData = async (checkoutId) => {
    try {
      const userRef = doc(collection(db, "cart"), checkoutId);
      const userSnapshot = await getDoc(userRef);
      if (userSnapshot.exists()) {
        const userData_ = userSnapshot.data();
        setCartData((prevState) => ({
          ...prevState,
          [checkoutId]: Object.values(userData_)[0],
        }));
        console.log(Object.values(userData_));
      } else {
        console.log("User does not exist");
      }
    } catch (error) {
      console.error("Error fetching user cart data: ", error);
    }
  };

  const updateCheckoutStatus = async (id, status) => {
    try {
      await updateDoc(doc(db, "checkout", id), {
        status: status,
      });
      console.log("Document successfully updated!");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const deleteCheckout = async (id) => {
    try {
      await deleteDoc(doc(db, "checkout", id));
      console.log("Document successfully deleted!");

      setCheckouts((prevState) =>
        prevState.filter((checkout) => checkout.id !== id)
      );
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const handleStatusChange = async (event, checkoutId) => {
    const newStatus = event.target.value;
    await updateCheckoutStatus(checkoutId, newStatus);
    setCheckouts((prevState) =>
      prevState.map((checkout) => {
        if (checkout.id === checkoutId) {
          return { ...checkout, status: newStatus };
        }
        return checkout;
      })
    );
  };

  const handleDetailButtonClick = (checkoutId) => {
    setExpandedCheckoutId(
      checkoutId === expandedCheckoutId ? null : checkoutId
    );
  };

  // Check screen size using media queries
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <div>
      <Typography variant="h4" style={{textAlign: "center"}} gutterBottom>
        ORDERS
      </Typography>
      {checkouts.map((checkout) => (
        <Accordion
          key={checkout.id}
          expanded={checkout.id === expandedCheckoutId}
          onChange={() => handleDetailButtonClick(checkout.id)}
        >
          <AccordionSummary expandIcon={<MdExpandMore />}>
            <Typography variant="h6">{checkout.fullName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <ListItemText primary={`Email: ${checkout.email}`} />
              </ListItem>
              <ListItem>
                <ListItemText primary={`Address: ${checkout.fullAddress}`} />
              </ListItem>
              <ListItem>
                <StyledListItem>
                  <ListItemText primary="Status:" />
                  <CenteredBox flexGrow={1}>
                    <FormControl>
                      <Select
                        value={checkout.status || ""}
                        onChange={(event) =>
                          handleStatusChange(event, checkout.id)
                        }
                      >
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="processing">Processing</MenuItem>
                        <MenuItem value="shipped">Shipped</MenuItem>
                        <MenuItem value="delivered">Delivered</MenuItem>
                      </Select>
                    </FormControl>
                  </CenteredBox>
                  {isSmallScreen ? (
                    <>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => deleteCheckout(checkout.id)}
                        style={{ marginRight: "8px" }}
                      >
                        <MdDelete />
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => handleDetailButtonClick(checkout.id)}
                      >
                        <MdInfo />
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        onClick={() =>
                          updateCheckoutStatus(checkout.id, "completed")
                        }
                        style={{ marginRight: "8px" }}
                      >
                        Mark as Completed
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => deleteCheckout(checkout.id)}
                      >
                        <MdDelete />
                      </Button>
                    </>
                  )}
                </StyledListItem>
              </ListItem>
              {checkout.id === expandedCheckoutId && (
                <ListItem>
                  <Accordion>
                    <AccordionSummary expandIcon={<MdExpandMore />}>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold", color: "#333" }}
                      >
                        Cart Data
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        {cartData[checkout.id]?.map((item, index) => (
                          <ListItem key={index}>
                            <ListItemText
                              primary={`${item.title}: ${item.quantity}`}
                              style={{ fontSize: "14px", color: "#666" }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </ListItem>
              )}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default AdminPanel;
