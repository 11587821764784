import "./App.css";
import { Header } from "./components/Header.js";
// import { Footer } from "./components/Footer.js";
import { Home } from "./pages/Home";
import { Event } from "./pages/Event";
import { Article } from "./pages/Article";
import { BookList } from "./pages/BookList";
import { Login } from "./pages/Login";
import { SignUp } from "./pages/SignUp";
import { Contact } from "./pages/Contact";
import { ProductDetails } from "./pages/ProductDetails"; // Import the ProductDetails component
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Accessories } from "./pages/Accessories";
import { Cart } from "./pages/Cart";
import { CashOnDeliveryForm } from "./pages/Delivery";
import { Typography } from "@mui/material";
import { AdminPanel } from "./pages/Admin";

function App() {
  const styles = {
    footer: {
      backgroundColor: "#f5f5f5",
      padding: "16px",
      textAlign: "center",
      marginTop: "auto",
    },
    content: {
      minHeight: "calc(110vh - 64px)", // Adjust this value if you have a fixed header
      display: "flex",
      flexDirection: "column",
    },
  };
  return (
    <BrowserRouter>
      <div style={styles.content}>
        <div>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/event" element={<Event />} />
            <Route path="/article" element={<Article />} />
            <Route path="/booklist" element={<BookList />} />
            <Route path="/accessories" element={<Accessories />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signUp" element={<SignUp />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/product/:id" element={<ProductDetails />} />
            <Route path="/delivery" element={<CashOnDeliveryForm />} />
            <Route path="/admin" element={<AdminPanel />} />
          </Routes>
        </div>
        {/* <Footer /> */}
        <footer style={styles.footer}>
          <Typography variant="body2" color="textSecondary">
            &copy; {new Date().getFullYear()} nailatheauthorui All rights
            reserved.
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Powered by&nbsp;<strong>NEXTECH</strong>
          </Typography>
        </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
